import React from "react";
import { SEO, Image, DynamicForm } from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class HiringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      idpbxlowqfmpletrhmiihvrnospyxykqajje,
      allFishermanBusinessLocation,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.hibiscussalon.com/hiring/"}
        />
        <Grid
          className={"custom-page-hiring custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"ejvzkfnopqoyvlze full-width"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column className={"nrrzrsuxgrclejug"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ maxHeight: "800px", minHeight: "240px" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "1bf34610-4fd0-432a-9e2d-803b87b4d661",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"okguhncffldnlfio"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column className={"iebckyjolzeghthd"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <DynamicForm
                  className={""}
                  style={{}}
                  businessId={26595973}
                  businessInfoId={1331}
                  websiteId={1374}
                  locationId={663}
                  componentConfigurationId={19946}
                  header={idpbxlowqfmpletrhmiihvrnospyxykqajje.data.header}
                  fields={
                    idpbxlowqfmpletrhmiihvrnospyxykqajje.data.dynamicFormFields
                  }
                  locations={allFishermanBusinessLocation.nodes}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 6306 }) {
      title
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
      }
    }
    idpbxlowqfmpletrhmiihvrnospyxykqajje: fishermanWebsiteComponent(
      fastId: { eq: "CustomForm" }
      componentIdentifier: { eq: "503e77e6-c939-4c4f-8f10-312e1e723c6d" }
    ) {
      data {
        header
        dynamicFormFields {
          name
          label
          options {
            label
            value
          }
          required
          inputType
          widgetType
          placeholder
          sendEmailToSelectedLocation
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        city
        name
        street
      }
    }
  }
`;
